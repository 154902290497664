.component {
	position: relative;
}

.title {
	padding-top: 40px;
	margin-bottom: 20px;
}

@media (max-width: 767px) {
	.component {
		margin-bottom: 40px;
	}

	.App-container {
		margin-top: 60px;
		min-height: calc(100vh - 400px - 60px);
	}

	#body {
		font-size: 0.875em;
	}

	#body h1 {
		font-size: 2em;
	}

	#body h2 {
		font-size: 1.75em;
	}

	#body h4 {
		font-size: 1.375em;
	}

	#body h6 {
		font-size: 1.125em;
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	.component {
		margin-bottom: 60px;
	}

	.App-container {
		margin-top: 60px;
		min-height: calc(100vh - 400px - 60px);
	}
}

@media (min-width: 1200px) {
	.component {
		margin-bottom: 80px;
	}

	.App-container {
		margin-top: 100px;
		min-height: calc(100vh - 400px - 90px);
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
}

#body h1 {
	font-weight: 800;
}

#body h4 {
	font-weight: 600;
}

#body h6 {
	font-weight: 500;
}

#body {
	font-family: 'Nunito Sans', 'sans-serif';
}
